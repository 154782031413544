// theme colors
export const primaryColor = `#373634`;
export const primaryMud = `#9F7058`;
export const quaternaryColor = `#13c2c2`;
export const quaternaryHoverColor = `#4ed1d1`;

export const successColor = '#268794';
export const warningColor = '#FEA904';
export const disabledColor = `rgba(221, 221, 221, 1)`;
export const secondaryDisabledColor = `rgba(186, 186, 186, 1)`;
export const errorColor = `#B04A3D`;
export const errorBackgroundColor = '#FDF6F5';
export const mutedAccentColor = `#dddddd`;
export const neutralBackgroundColor = `#ffffff`;

export const linkColor = '#46789E';
export const linkHoverColor = `#51abff`;
export const inputFocusColor = '#FAF6F3';
export const primaryHoverColor = `#535353`;
export const defaultHoverColor = `#e8e8e8`;

export const greenTintColor = `#EFFBFA`;
export const darkGreenTintColor = '#CDDFE1';
export const googleColor = `#ff4d4f`;
export const googleHoverColor = `#ff797b`;
export const facebookColor = `#4267b2`;
export const hopeLightPurple = `#784cab`;
export const forestGreen = '#3C5D62';
export const green = '#335E65';
export const lightGreen = '#34C759';

// neutrals
export const neutralOrangeTint = `#FAF6F3`;
export const neutralRust = `#8A4A29`;

export const neutral0 = '#FFFFFF';
export const neutral2 = '#fafafa';
export const neutral5 = '#f2f2f2';
export const neutral6 = '#c2c2c2';
export const neutral10 = '#f6f6f4';
export const neutral13 = '#dddddd';
export const neutral34 = '#a9a9a9';
export const neutral80 = '#333333';
export const neutral100 = '#88898A';

export const bronzeNeutral0 = '#997963';
export const bronzeNeutral2 = '#9F7058';
export const bronzeNeutral3 = '#E9E6DF';

export const white0 = '#FFFFFF';
export const white1 = '#F4F4F4';

export const black0 = '#000000';
export const black1 = '#1a1a1a';
export const black2 = '#393939';

export const lightBlue = '#F8F9FC';
export const blue0 = '#46789E';

export const rose0 = '#C4AD9E';

export const blossom = '#E5D2C3';

export const teal = '#268794';
export const tealTint = '#F5FBFC';

export const plum = '#653F43';
export const lightPlum = '#A17566';

// text
export const font = `'brandon-grotesque', Arial, sans-serif`;
export const secondaryFont = `'IvyMode', serif`;
export const tertiaryFont = `Arial, sans-serif`;
export const italicFont = `'Playfair Display', serif`;

export const textColor = `#373634`;
export const secondaryTextColor = `rgb(169,169,169,1)`; // #a9a9a9
export const redText = `#f4564e`;
export const deepRedText = `#B04A3D`;
export const secondaryRed = '#DB4437';

// shadows
export const cardShadow = `0px 1px 4px rgba(26, 26, 26, 0.25)`;

//########################## NEW DESIGN SYSTEM #################################/
//only use the colors and typography below, add new colors here

// colors
export const primaryColors = {
  blue: '#46789E',
  black: primaryColor,
  blackHover: '#5F5E5D',
  darkBlue100: '#2B3F50',
  darkBlue80: '#2B2E34',
  mud: '#9F7058',
  mudHover: '#BA8B76',
  white: '#ffffff',
  goldStar: '#D19400',
};

export const secondaryColors = {
  red: '#DB4437',
  darkRed: '#B04A3D',
  teal: '#268794',
  yellow: '#FEA904',
};

export const neutralColors = {
  lightOrangeTint: '#FAF6F3',
  lightBlueTint: '#F0F8FE',
  bronze: '#997963',
  bronzeTint: '#EEE9DE',
  gold: '#AB9B84',
  goldTint: '#EDE0BF',
  rust: '#8A4A29',
  cream: '#F4F3EF',
  darkOrangeCream: '#F9F3EA',
  orangeCream: '#FFFDFB',
  darkYellowBeige: '#E9E7E1',
  lightYellowBeige: '#F7F6F4',
  lightYellowTint: '#FFFCF0',
  mediumOrangeBeige: '#F2EDE6',
};

export const greyColors = {
  activeGrey: '#4F5153',
  greyDark: '#88898A',
  greyMedium: '#C2C6C9',
  lightGrey: '#E9EAE9',
  veryLightGrey: '#F2F2F2',
  barelyThereGrey: '#FAFAFA',
};

// typography

export const typography = {
  headerFontFamily: `'IvyMode', serif`,
  bodyFontFamily: `'brandon-grotesque', Arial, sans-serif`,
  accentFontFamily: `'Juniper and Sage', serif`,
  hestinaFontFamily: `'Hestina', serif`,
  gattewaySignatureFontFamily: `'Gatteway Signature', serif`,
  montserrat: `'Montserrat', serif`,
  sacramento: `'Sacramento', serif`,
  prata: `'Prata', serif`,
  alexBrush: `'AlexBrush', serif`,
};
